'use strict';

angular.module('windmanagerApp')
  .directive('moveTurbine', function () {
    return {
      templateUrl: 'components/directives/turbine/moveTurbine/moveTurbine.html',
      restrict: 'EA',
      controller: 'MoveTurbineCtrl',
      controllerAs: 'moveTurbine',
      scope: {
        turbine: '='
      },
      link: function (scope, element, attrs) {
      }
    };
  });
